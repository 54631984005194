import React, { useState, useCallback, useEffect } from "react";
import { Box } from "@mui/material";
import { useDropzone } from "react-dropzone";
import "./UploadDocument.css";

import { useDispatch } from "react-redux";
import AppButton from "../../../../buttons/AppButton";
import { showSimpleToast } from "../../../../../utils/alertFunctions";

const UploadDocumentsPage = ({ files, setFiles, setBase64s, base64s }) => {
  const [showResetBtn, setShowResetBtn] = useState(false);

  const onDrop = useCallback((acceptedFiles) => {
    console.log("something dropped",acceptedFiles);
    if(acceptedFiles.length > 1){
      showSimpleToast("You can only upload one document at a time", "failed");
      return;
    }
    console.log("before",acceptedFiles)

    const files = acceptedFiles.map((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        const binaryStr = reader.result;
        console.log("binary str", binaryStr)
        setBase64s([binaryStr]);
      };
      reader.readAsDataURL(file);
      return file;
    });
    setFiles(files);
    console.log("after",files)
  }, []);

  const { getRootProps, getInputProps, open, isDragActive } =
    useDropzone({
      onDrop,
      noClick: true,
      noKeyboard: true,
    });

  useEffect(() => {
    if (files.length > 0) {
      setShowResetBtn(true);
    } else {
      setShowResetBtn(false);
    }
  }, [files]);

  return (
    <>
      <div>
        <div {...getRootProps({ className: "dropzone" })}>
          <input className="input-zone" {...getInputProps()} />
          <div className="text-center">
            {isDragActive ? (
              <p className="dropzone-content">Release to drop the files here</p>
            ) : (
              <p className="dropzone-content">
                Drag and drop document here to upload
              </p>
            )}
            <button type="button" onClick={open} className="btn">
              Select from device
            </button>
          </div>
        </div>
        <Box sx={{display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center"}}>
           <ul>
          {files.map((file) => {
            return (
              <li key={file.path}>
                <p>{file.path}</p>
              </li>
            );
          })}
        </ul>

        {showResetBtn && (
          <AppButton
            customStyles={{
              fontWeight: "bold",
              borderRadius: "8px",
              height: "40px"
            }}
            fullWidth={false}
            variant="outlined"
            btnText="Reset"
            onClick={() =>   setFiles([])}
          />
        )}
        </Box>
       
      </div>

      <Box
        width="100%"
        m="0 auto"
        display="flex"
        flexDirection="column"
        alignItems="center"
        gap="20px"
      >
      </Box>
    </>
  );
};
export default UploadDocumentsPage;
