import { AccountBalanceWallet, Storage, Redeem, RemoveRedEye, Edit, Delete } from '@mui/icons-material'
import { 
    Box, Grid, Typography, Button, Table, TableContainer, 
    TableHead, TableRow, TableCell, TableBody, Paper, Menu, 
    MenuItem, ListItemIcon, Dialog, DialogActions, DialogContent, 
    DialogContentText, DialogTitle, CircularProgress 
  } from "@mui/material";
import React, { useEffect, useState } from 'react'
import DashboardCard from '../../components/dashboard/SharedComponents/DashboardCard'
import AppButton from '../../components/buttons/AppButton'
import { Link, useNavigate } from 'react-router-dom'
import { PAGES } from '../../utils/constant'
import { useDispatch, useSelector } from 'react-redux'
import { getAllMyResources, getMyResourceBalances } from '../../redux/resource/action'
import { formatAsCurrency, formatFileSize } from '../../utils/numberFunctions'
import { getLocalPrettyDate } from '../../utils/dateFunctions'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PublishIcon from '@mui/icons-material/Publish';
import { Block } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import { deleteResource  } from "../../redux/resource/action";


export default function ResourceList() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState(null);
    const [openDialog, setOpenDialog] = useState(false); // State for the delete dialog
    const [selectedResource, setSelectedResource] = useState({}); // Keep track of the resource to delete

    const open = Boolean(anchorEl);
  
    const handleClick = (event, resource) => {
      setAnchorEl(event.currentTarget);
      setSelectedResource(resource); 
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleDeleteClick = (resource) => {
        setOpenDialog(true); 
        handleClose(); 
      };
  
    const handleDialogClose = () => {
    setOpenDialog(false);
    };

    const handleDeleteConfirm = () => {
        // Dispatch the delete action
        dispatch(deleteResource({ id: selectedResource.id }))
          .then((result) => {
            if (result.meta.requestStatus === 'fulfilled') {
              // re-fetch the resources
              dispatch(getAllMyResources());
            }
          })
          .catch((error) => {
            console.error('Error deleting resource:', error);
          });
        setOpenDialog(false); // Close the dialog
    };
    


    const { isFetchingResources, resources, fetchingBalances, balances } = useSelector(state => state.resourceReducer);
    useEffect(() => {
        dispatch(getAllMyResources())
    }, [])

    useEffect(() => {
        dispatch(getMyResourceBalances());
    }, [])
    return (
        <Box
            m="10px auto 0"
            borderRadius="12px"
            p="20px"
        >
            <Grid
                container
                gap="10px"
                mb="50px"
            >
                <DashboardCard name="Total Earnings" value={formatAsCurrency(balances.totalEarnings, "₦")} icon={<Redeem color='primary' sx={styles.icon} />} noProgress />
                {/* <DashboardCard name="Current Balance" value="$200" icon={<AccountBalanceWallet color='primary' />} noProgress /> */}
                <CardWrapper>
                    <Box width="80%" display="flex" alignItems="center" gap="20px">
                        <AccountBalanceWallet color='primary' sx={styles.icon} />
                        <Box>
                            <Typography marginBottom={0} fontSize="16px" fontWeight="600" gutterBottom>
                                {formatAsCurrency(balances.totalWithdrawals, "₦")}
                            </Typography>
                            <Typography fontSize="12px">Current Balance </Typography>
                            <Button variant='outlined' size='small' sx={{ mt: "5px" }} onClick={() => alert("Email Safeticha@gmail.com to withdraw")}>
                                Withdraw
                            </Button>
                        </Box>
                    </Box>
                </CardWrapper> 


                {/* <CardWrapper>
                    <Box width="80%" display="flex" alignItems="center" gap="20px">
                        <Storage color='primary' sx={styles.icon} />
                        <Box>
                            <Typography marginBottom={0} fontSize="16px" fontWeight="600" gutterBottom>
                                Plan : Free
                            </Typography>
                            <Typography fontSize="12px" >10MB of 2GB Used </Typography>
                            <Button variant='outlined' size='small' sx={{ mt: "5px" }}>
                                Get More Storage
                            </Button>
                        </Box>
                    </Box>
                </CardWrapper> */}

            </Grid>

            <Box
                m="10px auto 0"
                backgroundColor="white"
                borderRadius="12px"
                p="20px"
            >
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", mb: 2 }}>
                    <Typography variant='h6'>List of Resources</Typography>
                    <AppButton btnText='Create Resource' fullWidth={false} onClick={() => navigate(PAGES.addResource)} />
                </Box>

                <Paper style={styles.tableResponsive}>
                    {isFetchingResources && <CircularProgress />}
                    {!isFetchingResources && <TableContainer>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>S/N</TableCell>
                                    <TableCell align="right">Title</TableCell>
                                    <TableCell align="right">Price</TableCell>
                                    <TableCell align="right">Size</TableCell>
                                    <TableCell align="right">Published</TableCell>
                                    <TableCell align="right">Created</TableCell>
                                    <TableCell align="right">Action</TableCell>
                                </TableRow>
                            </TableHead>

                            {!isFetchingResources && resources.length == 0 && <p style={{ textAlign: "center" }}>No resource found</p>}
                            <TableBody>
                                {resources.map((resource, index) => (
                                    <TableRow
                                        // key={index}
                                        key={resource.id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {index + 1}
                                        </TableCell>
                                        <TableCell align="right">{resource.title}</TableCell>
                                        <TableCell align="right">{formatAsCurrency(resource.price, "₦")}</TableCell>
                                        <TableCell align="right">{formatFileSize(resource.fileSize)}</TableCell>
                                        <TableCell align="right">{resource.published ? "Yes" : "No"}</TableCell>
                                        <TableCell align="right">{getLocalPrettyDate(resource.createdAt)}</TableCell>
                                        <TableCell align="right">
                                            <Button
                                            onClick={(event) => handleClick(event, resource)}
                                            variant="contained"
                                            sx={{
                                            backgroundColor: '#2E3192',
                                            color: '#fff', 
                                            borderRadius: '50px', 
                                            textTransform: 'none', 
                                            padding: '8px 24px', 
                                            fontWeight: 'bold',
                                            '&:hover': {
                                                backgroundColor: '#3b1769',
                                            },
                                            }}
                                        >
                                            Options
                                            </Button>

                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>}
                </Paper>

                    <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    slotProps={{ paper: {
                        elevation: 3,
                        style: { width: '200px', borderRadius:'12px', padding:"14px 0px 23px 0px"},
                    }}}
                 >
                    <MenuItem sx={{paddingLeft:"19px", paddingRight:"54px"}}>
                        <Link to={`/resources/${selectedResource.id}/edit`} style={{ textDecoration: 'none', display: 'flex', alignItems: 'center', color: 'inherit' }}>
                            <ListItemIcon>
                            <EditIcon fontSize="small" />
                            </ListItemIcon>
                            <Typography variant="inherit">Edit</Typography>
                        </Link>
                    </MenuItem>

                    {/* <MenuItem onClick={handleClose} sx={{paddingLeft:"19px", paddingRight:"54px"}}>
                    <ListItemIcon>
                        <VisibilityIcon fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="inherit">Preview</Typography>
                    </MenuItem> */}

                    {/* <MenuItem onClick={handleClose} sx={{paddingLeft:"19px", paddingRight:"54px"}}>
                    <ListItemIcon>
                        <PublishIcon fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="inherit">Publish</Typography>
                    </MenuItem> */}

                    {/* <MenuItem onClick={handleClose} sx={{paddingLeft:"19px", paddingRight:"54px"}}>
                    <ListItemIcon>
                    <Block fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="inherit">Unpublish</Typography>
                    </MenuItem> */}

                    <MenuItem onClick={() => handleDeleteClick(selectedResource)} style={{ color: 'red' }} sx={{paddingLeft:"19px", paddingRight:"54px"}}> 
                    <ListItemIcon>
                        <DeleteIcon fontSize="small" style={{ color: 'red' }} />
                    </ListItemIcon>
                    <Typography variant="inherit">Delete</Typography>
                    </MenuItem>
                </Menu>

                {/* Delete Confirmation Dialog */}
                <Dialog
                    open={openDialog}
                    onClose={handleDialogClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    PaperProps={{
                        style: { width: '500px', borderRadius:"19.5px", padding:"37.88px 40.35px 77.72px 41.65px" },
                    }}
                    sx={{
                        '& .MuiDialog-container': {
                            alignItems: 'center', 
                        },
                    }}
                >
                    <DialogTitle id="alert-dialog-title"
                    sx={{
                        fontWeight: 600,
                    }}
                    
                    >
                        {"Are you sure you want to delete this resource?"}
                    </DialogTitle>
                    {/* <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Deleting this resource is irreversible. Please confirm if you'd like to proceed with the deletion.
                        </DialogContentText>
                    </DialogContent> */}
                    <DialogActions sx={{ justifyContent: 'flex-start' }}>
                        <Button onClick={handleDialogClose} 
                        color="primary" 
                        sx={{
                        borderRadius: '3.848px',
                        background: '#FAFAFB',
                        padding:" 9px 19.04px",
                        border: "1.037px solid #2E3192",
                        color: '#000',
                        fontWeight: 600,
                                    }}>
                            No, Go Back
                        </Button>
                        <Button onClick={handleDeleteConfirm} color="primary" variant="contained" autoFocus
                            sx={{padding:" 9px 19.04px"}}
                        >

                            Yes, Delete
                        </Button>
                    </DialogActions>
                </Dialog>

            </Box>
        </Box>
    )
}

const CardWrapper = ({ children }) => {
    return <Grid
        item
        py="30px"
        backgroundColor="#ffffff"
        display="flex"
        flexDirection="column"
        alignItems="center"
        gap="20px"
        borderRadius="16px"
        sx={{ width: { xs: "100%", md: "49%", lg: "32.5%" } }}
    >
        {children}
    </Grid>
}

const styles = {
    tableResponsive: {
        overflowX: "auto",
        marginRight: "auto",
        marginLeft: "auto",
        maxWidth: "90vw",
    },
    icon: {
        width: { xs: "30px", md: "45px", lg: "50px" },
        height: { xs: "30px", md: "45px", lg: "50px" },
    }
}